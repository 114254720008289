<template>
  <form ref="wrapper" @submit.prevent="submit">
    <input value="Kontaktformular" name="form" type="hidden">
    <input :value="$store.state.router.locale" name="language" type="hidden">

    <h3 class="fo1">Oder schicken Sie uns eine Nachricht</h3>

    <div class="row-12 mt-2">
      <div class="col-12 md:col-4">
        <label for="lastname">Nachname*</label>
        <input
          id="lastname"
          name="last_name"
          type="text"
          autocomplete="family-name"
          placeholder="Musterman"
          required
        >
      </div>
      <div class="col-12 md:col-4">
        <label for="firstname">Vorname*</label>
        <input
          id="firstname"
          name="first_name"
          type="text"
          autocomplete="given-name"
          placeholder="Maria"
          required
        >
      </div>
    </div>

    <div class="row-12 mt-1 md:mt-2">
      <div class="col-12 md:col-4">
        <label for="email">E-Mail*</label>
        <input
          id="email"
          name="email"
          type="email"
          autocomplete="email"
          placeholder="maria@musterman.com"
          required
        >
      </div>
      <div class="col-12 md:col-4">
        <label for="phone">Telefon*</label>
        <input
          id="phone"
          name="phone"
          type="tel"
          autocomplete="tel"
          placeholder="41 61 311 04 50"
          required
        >
      </div>
    </div>

    <div class="mt-6">
      <label>Grund der Anfrage*</label>
      <div>
        <input
          id="allgemeine"
          type="radio"
          name="request"
          value="Allgemeine Anfrage"
          required
        >
        <label for="allgemeine">Allgemeine Anfrage</label>
      </div>
      <div>
        <input
          id="coaching"
          type="radio"
          name="request"
          value="Coaching Anfrage"
          required
        >
        <label for="coaching">Coaching Anfrage</label>
      </div>
      <div>
        <input
          id="eap"
          type="radio"
          name="request"
          value="EAP Beratung"
          required
        >
        <label for="eap">EAP Beratung</label>
      </div>
    </div>

<!--    <div class="row-12 mt-1">-->
<!--      <div class="col-12 md:col-8">-->
<!--        <label for="messageId">Ihre Nachricht*</label>-->
<!--        <textarea-->
<!--          id="messageId"-->
<!--          name="message"-->
<!--          rows="3"-->
<!--          required-->
<!--          placeholder="Hallo xstanding"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->

    <div class="row-12 mt-1">
      <div class="col-12 md:col-8">
        <label for="files">Falls Sie uns Dateien senden möchten können Sie diese hier hochladen</label>
        <input
          id="files"
          ref="file"
          name="file[]"
          type="file"
          class="w-auto"
          multiple
        >
      </div>
    </div>

    <div class="row-12 mt-4">
      <div class="col-12 md:col-8 flex">
        <input
          id="legal"
          type="checkbox"
          required
        >
        <label for="legal">
          Ich habe die <Go to="#" target="_blank">Datenschutzerklärung</Go> gelesen und akzeptiere diese.
          Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch
          erhoben und gespeichert werden.
        </label>
      </div>
    </div>

    <div class="row-12 mt-1">
      <div class="col-8">
        <button class="b">Absenden</button>
        <transition name="appear">
          <div v-if="result.message" class="response mt-4">
            {{ result.message }}
          </div>
        </transition>
      </div>
    </div>

  </form>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';

import axios from 'axios';

export default {
  setup() {
    const store = useStore();
    const wrapper = ref();
    const result = reactive({});

    const submit = async () => {
      try {
        const response = await axios.post(
          `${store.state.router.base}/de/ajax/forms/new_request/notify`,
          new FormData(wrapper.value),
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        result.message = response.data.message;
        wrapper.value.reset();
      } catch (e) {
        console.error(e);
        alert('Interner Fehler, bitte kontaktiere uns per Mail.');
      }
    };

    return {
      wrapper,
      result,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>

label {
  display: inline-block;
  font-size: 1rem;
  color: #000;
  margin-bottom: 0.3em;
}

input,
textarea {
  border: 1px solid var(--color-blue);
  display: block;
  width: 100%;
  padding: 0.3em;
  font-size: 1rem;
  color: #000;
}

input[type=radio],
input[type=checkbox] {
  display: inline-block;
  appearance: none;

  border-radius: 50%;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  border: 1px solid var(--color-blue);
  transition: 0.2s all linear;
  margin-right: 10px;

  position: relative;
  top: 4px;
}
input[type=checkbox] {
  border-radius: 0;
}

input:checked {
  border: 5.5px solid var(--color-blue);
  padding: 0;
}

input[type=file] {
  width: auto!important;
  border-width: 2px;
  color: #aaa;
}
</style>
