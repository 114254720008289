<template>
  <div class="Kontakt part" :class="[payload.optionen]">
    <div class="constrain">
      <h1 class="z2h">
        {{ page.titel }}
      </h1>

      <span class="l">
        {{ payload.texts.perPhone }}
        <Go :to="payload.baseLocation.telefon">
          {{ payload.baseLocation.telefon }}
        </Go>
        {{ payload.texts.or }}
        <br>
        {{ payload.texts.mail }}
        <Go :to="payload.baseLocation.mail">
          {{ payload.baseLocation.mail }}
        </Go>
      </span>

      <div class="locations">
        <div
          v-for="location in otherLocations"
          :key="location.id"
          class="location h1"
        >
          <span class="italic">
            {{ location.name }}
          </span>
          <span v-html="location.addresse" />
        </div>
      </div>

      <Form class="mt-16" />

      <div class="mt-12">
        <div class="row-12 mt-2">
          <div class="col-12 md:col-7">
            <div class="content" v-html="page.anfahrt" />
          </div>
          <div class="col-3 offset-1 hidden md:block">
            <div class="aspect a11 rounded-full">
              <img :src="page.kontaktbild.normal" :alt="page.kontaktbild.alt">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="map mt-12">
      <GoogleMap
        api-key="AIzaSyAtZtn_VGAX1ep80thv7mPLTnwpqMdJuNE"
        :center="options.position"
        :zoom="15"
        :styles="options.mapTheme"
      >
        <Marker :options="options" />
      </GoogleMap>
    </div>

  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GoogleMap, Marker } from 'vue3-google-map';
import Form from '@/components/elements/Form.vue';
import { mapTheme } from '../../../tailwind.config';

export default {
  components: { Form, GoogleMap, Marker },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const store = useStore();
    const page = computed(() => store.state.router.page.content);

    const options = {
      position: { lat: 47.55014294010439, lng: 7.595471908082294 },
      mapTheme: [
        {
          featureType: 'administrative.locality',
          elementType: 'all',
          stylers: [
            {
              hue: '#2c2e33',
            },
            {
              saturation: 7,
            },
            {
              lightness: 19,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              hue: '#ffffff',
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              hue: '#ffffff',
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#008eff',
            },
            {
              saturation: -93,
            },
            {
              lightness: 31,
            },
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              hue: '#bbc0c4',
            },
            {
              saturation: -93,
            },
            {
              lightness: 31,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels',
          stylers: [
            {
              hue: '#bbc0c4',
            },
            {
              saturation: -93,
            },
            {
              lightness: -2,
            },
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#e9ebed',
            },
            {
              saturation: -90,
            },
            {
              lightness: -8,
            },
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              hue: '#e9ebed',
            },
            {
              saturation: 10,
            },
            {
              lightness: 69,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              hue: '#e9ebed',
            },
            {
              saturation: -78,
            },
            {
              lightness: 67,
            },
            {
              visibility: 'simplified',
            },
          ],
        },
      ],
    };

    return {
      page,
      options,
      otherLocations: computed(() => page.value?.standorte?.filter((s) => s.id !== props.payload.baseLocation.id)),
      boxed: computed(() => props.payload.optionen?.includes('block')),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

h1 {
  color: var(--color-area);
  @include responsive("margin-bottom", 1.5rem, 2rem);
}

.Kontakt {
  color: var(--color-blue);
}

.content {
  color: #000;
  :deep(h2) {
    color: var(--color-blue);
  }
}

.locations {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  grid-gap: 2rem;
  @include responsive("margin-top", 3rem, 4rem);
}

.location {
  position: relative;
  padding: 1rem 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 15%;
    top: 0;
    left: 0;
    background: currentColor;
  }
  &::after {
    top: unset;
    bottom: 0;
  }
}

.map {
  :deep(.mapdiv) {
    height: 40vh;
  }
  :deep(.gmnoprint:first-of-type),
  :deep(.gm-style-mtc),
  :deep(.gm-fullscreen-control),
  :deep(.gm-bundled-control) {
    display: none !important;
  }
}

.content {
  :deep(h3) {
    margin-bottom: 0.2rem;
    font-size: pt(14);
  }
}

</style>
